import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { Button, Modal, Card ,  Form, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search,CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Cookies from 'js-cookie';
import { cloneDeep, isEmpty } from 'lodash';

import "./index.css";
class ListVoiceFiles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			voicelist: [],
			confirmDelete: false,
			confirmApprove: false,
			confirmReject: false,
			selectedPromptId: 0,
			selectedPromptName: '',
			customerId: '',
			sizePerPage: 10,
			selectedPromptUrl : '',
			ttsFlag : false,
			remarks : "",
			rejectRemarks : ""
		}
	}
	componentDidMount() {
		this.getPrompts();
		const savedSizePerPage = Cookies.get('voiceFileListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
	}

	getPrompts = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		if(this.props.app.role === 'ROLE_SADMIN')
		{
			Fetcher.get(apiPath + '/api/obd/prompts/list')
			.then(res => {
				this.setState({
					voicelist: res,
					confirmDelete: false,
					confirmApprove: false,
					confirmReject: false,
				})
			})
			.catch(err => { console.log('Error in fetching Voice Files', err) });
		}
		else
		{
			Fetcher.get(apiPath + '/api/obd/prompts/' + userId)
			.then(res => {
				this.setState({
					voicelist: res,
					confirmDelete: false,
					confirmApprove: false,
					confirmReject: false,
				})
			})
			.catch(err => { console.log('Error in fetching Voice Files', err) });
		}
	}

	showListenModal = (promptUrl, promptName, customerId) => {
		
		this.setState( {
			showListenModal: true,
			modalHeading: 'Listen Voice File',
			modalContent: <ReactPlayer url={`${promptUrl}`} playing />,
			selectedPromptName: promptName,
			selectedPromptUrl: promptUrl,
			customerId: customerId,
			copied: false,
			ttsFlag : false
		} )
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	showListenModalTTS = (promptUrl,promptName, customerId) => {
		console.log("Prompt Url "+promptUrl);
		this.setState( {
			showListenModal: true,
			modalHeading: 'Listen Voice File 1',
			modalContent: <ReactPlayer url={promptUrl} playing />,
			selectedPromptName: promptName,
			customerId: customerId,
			copied: false,
			selectedPromptUrl : promptUrl,
			ttsFlag : true
		} )
	}

	downloadVoiceFile = (promptUrl, promptName, customerId) => {
		window.open(promptUrl, 'Download');
	}

	confirmDelete = (status, promptId) => {
		this.setState({
			confirmDelete: status,
			confirmApprove: false,
			confirmReject: false,
			showListenModal: false,
			selectedPromptId: promptId
		});
	}

	confirmApprove = (status, promptId) => {
		this.setState({
			confirmApprove: status,
			confirmDelete: false,
			confirmReject: false,
			showListenModal: false,
			selectedPromptId: promptId
		});
	}

	confirmReject = (status, promptId) => {
		this.setState({
			confirmReject: status,
			confirmApprove: false,
			confirmDelete: false,
			showListenModal: false,
			selectedPromptId: promptId
		});
	}

	deletePrompt = () => {
		const promptId = this.state.selectedPromptId;
		const data = {

		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/prompt/' + promptId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getPrompts();
			})
			.catch(err => {
				console.log('Error in Deleting Voice File', err);
			});
	}

	approvePrompt = (event) => {

		event.preventDefault();
	    const formData = cloneDeep(this.state);
		console.log(formData);
		const promptId = this.state.selectedPromptId;
		const data = {
               promptId : promptId,
			   remarks : this.state.remarks
		}


		return Fetcher.post(this.props.app.apiPath + '/api/obd/prompt/approve', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getPrompts();
			})
			.catch(err => {
				console.log('Error in Approval', err);
			});
	}

	rejectPrompt = () => {
		const promptId = this.state.selectedPromptId;
		const data = {

			promptId : promptId,
			remarks : this.state.rejectRemarks

		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/prompt/reject', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getPrompts();
			})
			.catch(err => {
				console.log('Error in Rejecting', err);
			});
	}

	setMessageShow = ( status ) => {
		this.setState( {
			showListenModal: false,
		} );
	}

	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('voiceFileListsizePerPage', newSizePerPage);
	}

	render() {

		const prompts = this.state.voicelist;
		var columns='';
		if(this.props.app.role === 'ROLE_SADMIN')
		{
			columns = [
			{
				dataField: 'promptId',
				text: 'Voice File Id',
				sort: true,
				hidden: false
			},
			{
				dataField: 'promptUrl',
				text: 'Prompt Url',
				sort: true,
				hidden: true
			},
			{
				dataField: 'name',
				text: 'File Name',
				sort: true,
				searchable: true
			}, {
				dataField: 'promptCategory',
				text: 'File Type',
				sort: false,
				searchable: false
			}, {
				dataField: 'promptStatus',
				text: 'Status',
				sort: false,
				searchable: false
			},
			{
				dataField: 'username',
				text: 'Uploaded By',
				sort: true,
				searchable: true
			},
			{
				dataField: 'accountType',
				text: 'Account',
				sort: false,
				searchable: false
			},
			{
				dataField: 'uploadTime',
				text: 'Upload Time',
				sort: false,
				searchable: false
			},
			{
				dataField: 'approvalTime',
				text: 'Approval Time',
				sort: false,
				searchable: false
			},
			{
				dataField: 'delete_date',
				text: 'Deletion Date',
				sort: false,
				searchable: false
			},
			{
				dataField: 'remarks',
				text: 'Remarks',
				sort: false,
				searchable: false
			},
			{
				dataField: 'action',
				text: 'Action',
				sort: false,
				searchable: false
			},];
		}
		else if( this.props.app.role === 'ROLE_ADMIN')
		{
			columns = [
			{
				dataField: 'promptId',
				text: 'Voice File Id',
				sort: true,
				hidden: false
			},
			{
				dataField: 'promptUrl',
				text: 'Prompt Url',
				sort: true,
				hidden: true
			},
			{
				dataField: 'name',
				text: 'File Name',
				sort: true,
				searchable: true
			}, {
				dataField: 'promptCategory',
				text: 'File Type',
				sort: false,
				searchable: false
			}, {
				dataField: 'promptStatus',
				text: 'Status',
				sort: false,
				searchable: false
			},
			{
				dataField: 'username',
				text: 'Uploaded By',
				sort: true,
				searchable: true
			},
			{
				dataField: 'accountType',
				text: 'Account',
				sort: false,
				searchable: false
			},
			{
				dataField: 'action',
				text: 'Action',
				sort: false,
				searchable: false
			},];
		}
		else
		{
			columns = [
				{
					dataField: 'promptId',
					text: 'Voice File Id',
					sort: true,
					hidden: false
				},
				{
				dataField: 'promptUrl',
				text: 'Prompt Url',
				sort: true,
				hidden: true
			    },
				{
					dataField: 'name',
					text: 'File Name',
					sort: true,
					searchable: true
				}, {
					dataField: 'promptCategory',
					text: 'File Type',
					sort: false,
					searchable: false
				}, {
					dataField: 'promptStatus',
					text: 'Status',
					sort: false,
					searchable: false
				},
				{
					dataField: 'accountType',
					text: 'Account',
					sort: false,
					searchable: false
				},
				{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false
				},];
		}

		const products = Array.isArray(prompts) ? prompts.map((prompt, index) => {
			var status='';
			switch ( prompt.promptStatus ) {
				case 0:
					status = 'Pending';
					break;
				case 1:
					status = 'Approved';
					break;
				case 2:
					status = 'Rejected';
					break;
				case 9:
					status = 'Deleted';
					break;
				default:
					status = '';
					break;
			}
			var account='';
			switch ( prompt.accountType ) {
				case 0:
					account = 'Promotional';
					break;
				case 1:
					account = 'Transactional';
					break;
				default:
					account = '';
					break;
			}
			let action = [];
			
			if(this.props.app.role === 'ROLE_SADMIN')
			{
				if((prompt.promptStatus === 0) && (prompt.accountType === 1 || prompt.accountType === 0))
				{
					action = [<a key={`${index}-approve-file`} className="btn-sm btn btn-default btn-success ml-2" onClick={(e) => { this.confirmApprove(true, prompt.promptId) }} href="#">Approve</a>];
					action.push(<a key={`${index}-reject-file`} className="btn-sm btn btn-default btn-danger ml-2" onClick={(e) => { this.confirmReject(true, prompt.promptId) }} href="#">Reject</a>);
					if(prompt.promptCategory == 'tts'){
                    action.push(<a key={`prompt1-listen-${index}`} className=" btn-sm btn btn-default btn-primary ml-2" onClick={(e) => { this.showListenModalTTS(prompt.promptUrl,prompt.fileName, prompt.userId) }} href="#">Listen</a>);
					
					}else{
					action.push(<a key={`prompt1-listen-${index}`} className=" btn-sm btn btn-default btn-primary ml-2" onClick={(e) => { this.showListenModal(prompt.promptUrl, prompt.fileName, prompt.userId) }} href="#">Listen</a>);
					}
				}
				else
				{
					//action = [<a key={`prompt-${index}`} className=" btn-sm btn btn-default btn-primary" onClick={(e) => { this.showListenModal(prompt.promptUrl, prompt.fileName, prompt.userId) }} href="#">Listen</a>];
				   if(prompt.promptCategory == 'tts'){
                       action = [<a key={`prompt-${index}`} className=" btn-sm btn btn-default btn-primary" onClick={(e) => { this.showListenModalTTS(prompt.promptUrl,prompt.fileName, prompt.userId) }} href="#">Listen</a>];
					}else{
						action = [<a key={`prompt-${index}`} className=" btn-sm btn btn-default btn-primary" onClick={(e) => { this.showListenModal(prompt.promptUrl, prompt.fileName, prompt.userId) }} href="#">Listen</a>];
					  }
				}
				return {
					promptId: prompt.promptId,
					name: prompt.fileName,
					promptCategory: prompt.promptCategory,
					promptStatus: status,
					accountType: account,
					username: prompt.username,
					action: action,
					uploadTime: prompt.uploadTime,
					delete_date : prompt.delete_date,
					remarks : prompt.remarks,
					approvalTime: prompt.approvalTime,
				};
			}
			else if(this.props.app.role === 'ROLE_ADMIN')
			{
				if(prompt.promptCategory=='tts'){
                  action = [<a key={`prompt-listen-${index}`} className=" btn-sm btn btn-default btn-success" onClick={(e) => { this.showListenModalTTS(prompt.promptUrl,prompt.fileName, prompt.userId) }} href="#">Listen</a>];
				}else{
				  action = [<a key={`prompt-listen-${index}`} className=" btn-sm btn btn-default btn-success" onClick={(e) => { this.showListenModal(prompt.promptUrl, prompt.fileName, prompt.userId) }} href="#">Listen</a>];
				}
				action.push(<a key={`prompt-download-${index}`} className=" btn-sm btn btn-default btn-primary" onClick={(e) => { this.downloadVoiceFile(prompt.promptUrl, prompt.fileName, prompt.userId) }} href="#">Download</a>);
				return {
					promptId: prompt.promptId,
					name: prompt.fileName,
					promptCategory: prompt.promptCategory,
					promptStatus: status,
					accountType: account,
					username: prompt.username,
					action: action
				};
			}
			else
			{
				action = [<a data-tip="Delete File" key={`${index}-delete-file`} className="btn-sm btn btn-default btn-danger ml-2r" onClick={(e) => { this.confirmDelete(true, prompt.promptId) }} href="#">Delete</a>];
				if(prompt.promptCategory=='tts'){
					 action.push(<a key={`prompt-listen-${index}`} className=" btn-sm btn btn-default btn-success" onClick={(e) => { this.showListenModalTTS(prompt.promptUrl,prompt.fileName, prompt.userId) }} href="#">Listen</a>);
				
				}
				else{
				action.push(<a key={`prompt-listen-${index}`} className=" btn-sm btn btn-default btn-success ml-2" onClick={(e) => { this.showListenModal(prompt.promptUrl, prompt.fileName, prompt.userId) }} href="#">Listen</a>);
				}
				action.push(<a key={`prompt-download-${index}`} className=" btn-sm btn btn-default btn-primary ml-2  " onClick={(e) => { this.downloadVoiceFile(prompt.promptUrl, prompt.fileName, prompt.userId) }} href="#">Download</a>);
				return {
					promptId: prompt.promptId,
					name: prompt.fileName,
					promptCategory: prompt.promptCategory,
					promptStatus: status,
					accountType: account,
					action: action
				};
			}
			
		}) : []
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'promptId',
			order: 'desc'
		}];

		const { ExportCSVButton } = CSVExport;

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};

		return (
			<Card>
				<Card.Header>Voice Files</Card.Header>
				<Card.Body>
					<div className="campaign-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (<ToolkitProvider 
								                    keyField="id"
													data={products}
													columns={columns}
													exportCSV={ {
										            	fileName: 'voice_file.csv'
										            } }
													search bootstrap4>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
										<hr/>
										<BootstrapTable bordered={true} condensed defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="voicelist-table" {...props.baseProps} {...paginationTableProps} noDataIndication={() => (<div>{'No data available'}</div>)} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Delete Voice File
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to delete this file?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deletePrompt} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>
					<Modal size="md" show={this.state.confirmApprove} onHide={() => this.confirmApprove(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Approve Voice File
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to approve this file?
						</Modal.Body>
						<Modal.Footer>
                            
							<Form className="add-campaign-form" onSubmit={(e) => this.approvePrompt(e)}>
								<Row style={{marginRight:'230px'}}>
									<Col md={12}>
										<Form.Group className="form-group"  controlId="remarks">
											<Form.Label>Remarks</Form.Label>
											<Form.Control  required name="remarks" onChange={e => this.setvalue(e)} value={this.state.remarks} type="text" placeholder="Remarks" />
										</Form.Group>
									</Col>
								</Row>

								<Row style={{marginRight:'200px'}}>
									<Col sm={3}>
									  <Button variant="primary" type="submit">YES</Button>
									</Col>
									<Col sm={3}>	 
									  <Button onClick={() => this.confirmApprove(false, '')} variant="secondary">CANCEL</Button>
									</Col>
								</Row>            
							</Form>

							{/* <Button onClick={() => this.confirmApprove(false, '')} variant="secondary">CANCEL</Button> */}
							{/* <Button onClick={this.approvePrompt} variant="primary">YES</Button> */}
						</Modal.Footer>
					</Modal>
					<Modal size="md" show={this.state.confirmReject} onHide={() => this.confirmReject(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Reject Voice File
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to reject this file?
						</Modal.Body>
						<Modal.Footer>
                                
							<Form className="add-campaign-form" onSubmit={(e) => this.rejectPrompt(e)}>
								<Row style={{marginRight:'230px'}}>
									<Col md={12}>
										<Form.Group className="form-group"  controlId="remarks">
											<Form.Label>Remarks</Form.Label>
											<Form.Control  required name="rejectRemarks" onChange={e => this.setvalue(e)} value={this.state.rejectRemarks} type="text" placeholder="Remarks" />
										</Form.Group>
									</Col>
								</Row>

								<Row style={{marginRight:'200px'}}>
									<Col sm={3}>
									  <Button variant="primary" type="submit">YES</Button>
									</Col>
									<Col sm={3}>	 
									  <Button onClick={() => this.confirmReject(false, '')} variant="secondary">CANCEL</Button>
									</Col>
								</Row>            
							</Form>

							{/* <Button onClick={() => this.confirmReject(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.rejectPrompt} variant="primary">YES</Button> */}
						</Modal.Footer>
					</Modal>
					<Modal size="lg" show={this.state.showListenModal} onHide={() => this.setMessageShow( false )}>
						<Modal.Header closeButton>
							<Modal.Title className="modal-heading" id="example-modal-sizes-title-sm">
								{this.state.modalHeading}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body >
							{this.state.ttsFlag ? 
							<ReactPlayer url={this.state.selectedPromptUrl} controls width='90%' height='80px' />
							: <ReactPlayer url={`${this.state.selectedPromptUrl}`} controls width='90%' height='80px' />
							}
							</Modal.Body>

					</Modal>
				</Card.Body>
			</Card>
		);
	}
}


export default ListVoiceFiles;